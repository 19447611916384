export default {
    methods: {
        redirectBack() {
            if (this.$route.path !== '/community') {
                return;
            }

            if (window.history.length > 2) {
                this.$router.back();
                return;
            }

            this.$router.push('/');
        }
    }
}
