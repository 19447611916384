<template>
	<Modal class="modal--register" classes="max-h-700" :auth-modal="true" @close="redirectBack">
		<template #header>
			<VHeading level="2">Register your Takwimu account</VHeading>
		</template>
		<template #content>
			<div class="modal-section__card__content__inner">
				<VText
					size="big"
					weight="bold"
					classes="modal-section__card__content__inner__title"
				>
					Register by email
				</VText>

				<form action="" class="form__column">
					<VDefaultField :error="errors.get('firstName')">
						<template #field>
							<VInput
								v-model="form.firstName"
								type="email"
								placeholder="First name"
								:error="errors.get('firstName')"
								@input="errors.clear('firstName')"
							/>
						</template>
					</VDefaultField>

					<VDefaultField :error="errors.get('lastName')">
						<template #field>
							<VInput
								v-model="form.lastName"
								type="text"
								placeholder="Last name"
								:error="errors.get('lastName')"
								@input="errors.clear('lastName')"
							/>
						</template>
					</VDefaultField>

					<VDefaultField :error="errors.get('email')">
						<template #field>
							<VInput
								v-model="form.email"
								type="email"
								placeholder="Email"
								:error="errors.get('email')"
								@input="errors.clear('email')"
							/>
						</template>
					</VDefaultField>

					<VDefaultField :error="errors.get('password')">
						<template #field>
							<VInput
								v-model="form.password"
								type="password"
								placeholder="Password"
								:error="errors.get('password')"
								@input="errors.clear('password')"
							/>
						</template>
					</VDefaultField>

					<VDefaultField :error="errors.get('password_confirmation')">
						<template #field>
							<VInput
								v-model="form.password_confirmation"
								type="password"
								placeholder="Confirm password"
								:error="errors.get('password_confirmation')"
								@input="errors.clear('password_confirmation')"
							/>
						</template>
					</VDefaultField>

					<VDefaultField :error="errors.get('agree')">
						<template #field>
							<VCheckbox
								id="terms"
								v-model="form.agree"
								:error="errors.get('agree')"
								@input="errors.clear('agree')"
							>
								I agree to your
								<VLink size="small" href="/privacy" target="_blank">
									terms and privacy policy
								</VLink>
							</VCheckbox>
						</template>
					</VDefaultField>

					<VButton color="primary" @click.stop="onSubmit">
						Register
					</VButton>
				</form>
			</div>
			<SocialAuth type="Register"/>
		</template>
	</Modal>
</template>

<script>
import { mapState } from 'vuex';
import { REGISTER } from '@/store/actions.type';
import { SET_ACTIVE_MODAL, CLEAR_ERRORS } from '@/store/mutations.type';

import modal from '@/mixins/modal';

export default {
	name: 'ModalRegister',
  mixins: [modal],
	data() {
		return {
			form: {
				firstName: '',
				email: '',
				password: '',
				password_confirmation: '',
				agree: false
			}
		};
	},
	computed: {
		...mapState({
			errors: state => state.auth.errors
		})
	},
	created() {
		this.$store.commit(CLEAR_ERRORS);
	},
	methods: {
		async onSubmit() {
			await this.$store.dispatch(REGISTER, this.form);
			this.$store.commit(SET_ACTIVE_MODAL, null);

			window.location.reload(); // todo needs solution without reload
		}
	}
};
</script>

<style scoped>
/deep/ .form__checkbox + .form__label {
	padding-left: 0;
}

/deep/ .form__checkbox:checked + .form__label:after {
	top: 3px;
}
</style>
